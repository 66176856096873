
import { defineComponent } from 'vue';
import YouTube from 'vue3-youtube';
import Markdown from 'vue3-markdown-it';
import MarkdownAbbr from 'markdown-it-abbr';
import MarkdownAnchor from 'markdown-it-anchor';
import MarkdownDeflist from 'markdown-it-deflist';
import MarkdownEmoji from 'markdown-it-emoji';
import MarkdownFootnote from 'markdown-it-footnote';
import MarkdownHighlightJs from 'markdown-it-highlightjs';
import MarkdownIns from 'markdown-it-ins';
import MarkdownMark from 'markdown-it-mark';
import MarkdownSub from 'markdown-it-sub';
import MarkdownSup from 'markdown-it-sup';
import MarkdownTaskLists from 'markdown-it-task-list';
import MarkdownTocDoneRight from 'markdown-it-toc-done-right';

import Panel from '@/components/layout/Panel.vue';
import ViewMixin from '@/mixins/ViewMixin';
import * as yololProjectService from '@/services/yolol/yololProjectService';
import * as yololScriptService from '@/services/yolol/yololScriptService';
import * as publicUserService from '@/services/social/publicUserService';
import * as likeService from '@/services/social/likeService';
import { YololProject, FetchedYololProject } from '@/interfaces/yolol/yololProject';
import LoadingIndicatorBeam from '@/components/loading/LoadingIndicatorBeam.vue';
import EditYololProject from '@/components/yolol/yololProject/EditYololProject.vue';
import EditYololScript from '@/components/yolol/yololScript/EditYololScript.vue';
import YololScriptComponent from '@/components/yolol/yololScript/YololScript.vue';
import 'highlight.js';
import 'highlight.js/styles/monokai.css';
import { JwtUser } from '@/interfaces/identity/user';
import Button from '@/components/controls/Button';
import { PublicUser } from '@/interfaces/social/publicUser';
import { YololScript } from '@/interfaces/yolol/yololScript';
import DateTimeFormatter from '@/components/formatters/DateTimeFormatter.vue';
import { Like } from '@/interfaces/social/like';
import { redirectToDiscord, uniqueId } from '@/helpers/index';
import { EXAMPLE_FETCH_CONFIG } from '@/constants/ingame';
import FetchConfigCheck from '@/components/yolol/yololProject/FetchConfigCheck.vue';

interface Data {
    searchTerm: string;
    isLoading: boolean;
    yololProject: YololProject | null;
    fetchedYololProject: FetchedYololProject | null;
    showEdit: boolean;
    creator: PublicUser | null;
    yololScripts: YololScript[];
    showCreateYololScript: boolean;
    likes: Like[];
    editDocumentation: string;
}

export default defineComponent({
    name: 'YololProject',
    components: {
        Button,
        DateTimeFormatter,
        EditYololProject,
        EditYololScript,
        FetchConfigCheck,
        YololScript: YololScriptComponent,
        Markdown,
        Panel,
        LoadingIndicatorBeam,
        YouTube,
    },
    mixins: [ViewMixin],
    props: {
        yololProjectId: {
            type: String,
            default: null,
        },
    },
    data: (): Data => ({
        searchTerm: '',
        isLoading: false,
        yololProject: null,
        fetchedYololProject: null,
        showEdit: false,
        creator: null,
        yololScripts: [],
        showCreateYololScript: false,
        likes: [],
        editDocumentation: '',
    }),
    watch: {
        yololProjectId(): void {
            this.refreshData();
        },
    },
    computed: {
        exampleFetchConfig(): string {
            return EXAMPLE_FETCH_CONFIG;
        },
        isAdmin(): boolean {
            return this.$store.getters['authentication/hasOneRoles']([]);
        },
        isCreator(): boolean | null {
            return this.user && this.yololProject && this.user.id === this.yololProject.creatorId;
        },
        hasEditRights(): boolean | null {
            if (this.user && this.yololProject) {
                if (this.user.id === this.yololProject.creatorId) return true;
                if (this.isAdmin) return true;
            }
            return false;
        },
        user(): JwtUser | null {
            return this.$store.getters['authentication/user'];
        },
        currentUserLike(): Like | undefined {
            if (this.user) {
                return this.likes.find(({ userId }) => this.user && this.user.id === userId);
            }
            return undefined;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        markdownPlugins(): Record<string, any>[] {
            return [
                { plugin: MarkdownAbbr },
                { plugin: MarkdownAnchor },
                { plugin: MarkdownDeflist },
                { plugin: MarkdownEmoji },
                { plugin: MarkdownFootnote },
                { plugin: MarkdownHighlightJs },
                { plugin: MarkdownMark },
                { plugin: MarkdownIns },
                { plugin: MarkdownSub },
                { plugin: MarkdownSup },
                { plugin: MarkdownTaskLists },
                { plugin: MarkdownTocDoneRight },
            ];
        },
        previewImageStyle(): Record<string, string> {
            return {
                backgroundImage: this.yololProject && this.yololProject.previewImageUri ? `url('${this.yololProject.previewImageUri}')` : '',
            };
        },
        editFormDocumentation(): string | null {
            if (this.$refs.editYololProject) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return (this.$refs.editYololProject as any).data.properties.documentation as string | null;
            }
            return null;
        },
        documentation(): string | null {
            if (this.fetchedYololProject) {
                return this.fetchedYololProject.documentation;
            }
            if (this.yololProject) {
                return this.yololProject.documentation;
            }
            return null;
        },
        scripts(): YololScript[] {
            if (this.fetchedYololProject) {
                return this.fetchedYololProject.scripts.map((script) => ({
                    id: uniqueId(),
                    code: script.code,
                    name: script.name,
                    projectId: this.yololProjectId,
                    createdAt: '',
                    updatedAt: null,
                }));
            }
            return this.yololScripts;
        },
    },
    methods: {
        redirectToDiscord,
        handleUpdateDocumentation(documentation: string): void {
            this.editDocumentation = documentation;
        },
        handleScriptCreated(): void {
            this.showCreateYololScript = false;
            this.loadYololScripts();
        },
        handleCreated(yololProject: YololProject): void {
            this.$router.push({
                name: 'yolol_yolol-project',
                params: {
                    yololProjectId: yololProject.id,
                },
            });
        },
        handlePatched(): void {
            this.showEdit = false;
            this.refreshData();
        },
        handleCancelled(): void {
            this.showEdit = false;
            if (!this.yololProjectId) {
                this.$router.push({ name: 'yolol_yolol-projects' });
            }
        },
        async refreshData(): Promise<void> {
            this.yololProject = null as YololProject | null;
            this.creator = null;
            this.yololScripts = [];
            this.likes = [];
            this.fetchedYololProject = null;
            await this.loadYololProject();
            await this.loadCreator();
            if (this.yololProject && this.yololProject.fetchConfigUri) {
                await this.loadFetchedYololProject();
            } else {
                await this.loadYololScripts();
            }
            await this.loadLikes();
        },
        async loadYololProject(): Promise<void> {
            if (!this.yololProjectId) {
                return;
            }
            this.isLoading = true;
            try {
                const response = await yololProjectService.getOneOrDefault(this.yololProjectId);
                this.yololProject = response.data;
                this.setPageTitle([this.yololProject.name, this.$t('yololLibrary'), this.$t('yolol')]);
            } catch (_) {
                // do nothing
            }
            this.isLoading = false;
        },
        async loadCreator(): Promise<void> {
            if (!this.yololProject) {
                return;
            }
            this.isLoading = true;
            try {
                const response = await publicUserService.getOneOrDefault(this.yololProject.creatorId);
                this.creator = response.data;
            } catch (_) {
                // do nothing
            }
            this.isLoading = false;
        },
        async loadYololScripts(): Promise<void> {
            if (!this.yololProjectId) {
                return;
            }
            this.isLoading = true;
            try {
                const response = await yololScriptService.getMultiple({
                    projectIds: this.yololProjectId,
                    pageSize: -1,
                });
                this.yololScripts = response.data;
            } catch (_) {
                // do nothing
            }
            this.isLoading = false;
        },
        async loadFetchedYololProject(): Promise<void> {
            if (!this.yololProject || !this.yololProject.fetchConfigUri) {
                return;
            }
            this.isLoading = true;
            try {
                const response = await yololProjectService.loadByFetchConfig(this.yololProjectId);
                this.fetchedYololProject = response.data;
            } catch (_) {
                // do nothing
            }
            this.isLoading = false;
        },
        async loadLikes(): Promise<void> {
            if (!this.yololProjectId) {
                return;
            }
            this.isLoading = true;
            try {
                const response = await likeService.getMultiple({
                    yololProjectIds: this.yololProjectId,
                    pageSize: -1,
                });
                this.likes = response.data;
            } catch (_) {
                // do nothing
            }
            this.isLoading = false;
        },
        async like(): Promise<void> {
            if (!this.yololProjectId) {
                return;
            }
            this.isLoading = true;
            try {
                await likeService.create({
                    yololProjectId: this.yololProjectId,
                });
                this.$notify({
                    type: 'success',
                    title: this.$t('thankYou'),
                });
            } catch (_) {
                this.$notify({
                    type: 'error',
                    title: this.$t('likingFailed'),
                });
            }
            this.isLoading = false;
            this.loadLikes();
        },
        async unlike(id: string): Promise<void> {
            if (!this.yololProjectId) {
                return;
            }
            this.isLoading = true;
            try {
                await likeService.deleteSingle(id);
                this.$notify({
                    type: 'success',
                    title: this.$t('thatsSad'),
                });
            } catch (_) {
                this.$notify({
                    type: 'error',
                    title: this.$t('unlikingFailed'),
                });
            }
            this.isLoading = false;
            this.loadLikes();
        },
    },
    created(): void {
        this.setPageTitle([this.$t('yololLibrary'), this.$t('yolol')]);
        this.refreshData();
    },
});
