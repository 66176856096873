
import { defineComponent } from 'vue';
import { YololScript } from '@/interfaces/yolol/yololScript';
import EditYololScript from '@/components/yolol/yololScript/EditYololScript.vue';
import DateTimeFormatter from '@/components/formatters/DateTimeFormatter.vue';
import Button from '@/components/controls/Button';
import { uniqueId, getRequiredYololChip } from '@/helpers';

interface Data {
    showEdit: boolean;
    textToCopy: string;
}

export default defineComponent({
    name: 'YololScript',
    emits: ['patched'],
    components: {
        Button,
        DateTimeFormatter,
        EditYololScript,
    },
    props: {
        yololScript: {
            type: Object,
            required: true,
        },
        editRights: {
            type: Boolean,
            default: false,
        },
    },
    data: (): Data => ({
        showEdit: false,
        textToCopy: '',
    }),
    computed: {
        typedYololScript(): YololScript {
            return this.yololScript as YololScript;
        },
        clipboardInputId(): string {
            return `clipboardInput_${this.yololScript.id}_${uniqueId}}`;
        },
        requiredChip(): string {
            return getRequiredYololChip(this.yololScript.code);
        },
        lastUpdate(): string | null {
            return this.yololScript.updatedAt ? this.yololScript.updatedAt : this.yololScript.createdAt;
        },
    },
    methods: {
        copyToClipboard(text: string): void {
            this.textToCopy = text;
            const input = document.getElementById(this.clipboardInputId) as HTMLInputElement;

            this.$nextTick(() => {
                /* Select the text field */
                input.select();
                /* For mobile devices */
                input.setSelectionRange(0, 99999);

                document.execCommand('copy');

                this.$notify({
                    title: this.$t('copied'),
                    text: this.$t('codeCopied'),
                    type: 'success',
                });
            });
        },
        handlePatched(): void {
            this.showEdit = false;
            this.$emit('patched');
        },
    },
});
